'use strict';

angular.module('controller.dashboard.documents', [
    'service.statements',
    'directive.mobile-list-cards',
    'service.group-by-date',
    'service.timeframeDisplayHelper',
    'ram'
  ])
  .controller('DashboardDocumentsCtrl', [
    '$state',
    '$scope',
    '$analytics',
    'ram',
    'statements',
    'taxDocuments',
    'accountOpeningDocuments',
    'confirmsDocuments',
    'groupByDateService',
    'timeframeDisplayHelper',
    documentsController
  ]);

function documentsController($state, $scope, $analytics, ram, statements, taxDocuments, accountOpeningDocuments, confirmsDocuments, groupByDateService, timeframeDisplayHelper) {
  $scope.changeTab = (newTab, $event) => {
    $scope.tab = newTab

    try {
      $analytics.tabTrack([{
        id: `documents-${$scope.tab}`,
        name: $event.target.innerText,
        type: 'tab'
      }], {
        lists: [{
          id: $scope.tab,
          name: $event.target.innerText,
          type: $scope.tab
        }]
      })
    } catch(error) {
      console.error(error)
      window.Sentry && window.Sentry.captureException(error);
    }
  }

  $scope.hideMarginLRForAdvisorInfo = true;

  $scope.statementTypes = [{
    id: 1,
    label: 'All statements',
    name: null
  }, {
    id: 2,
    label: 'Quarterly performance',
    name: 'qtr_statement'
    // Future can add things like this
  }, {
    id: 3,
    label: 'Annual performance report',
    name: 'annual_performance_report'
  }];

  $scope.periods = timeframeDisplayHelper.getPeriods();

  $scope.accountId = function(id) {
    if (arguments.length) {
      $state.go($state.current, {
        accountId: id
      });
    }
    return $scope.account.id;
  };

  $scope.statementType = new ram.Accessor($scope.statementTypes[0]);
  $scope.period = new ram.Accessor($scope.periods[3]);

  var visibleAccountIds = $scope.accountMenu.map(function(account) {
    return account.id;
  });

  function filterByAccount(documents) {
    return documents.filter(function(document) {
      return (!(document.account) ||
        _.contains(visibleAccountIds, document.account.id)
      );
    });
  }

  $scope.documents = filterByAccount(accountOpeningDocuments);
  $scope.confirmsDocs = filterByAccount(confirmsDocuments);
  $scope.taxDocuments = filterByAccount(taxDocuments);

  $scope.statements = statements; // statements will be filtered based on a combination of filters in a watch group below.
  $scope.statementsByDate = [];

  var _getQueriedAccountIds = function(query) {
    var queriedAccountIds = [];
    if (query.accountId === '-' || query.accountId === 'h') {
      _.each($scope.account.visibleAccounts(), function(account) {
        queriedAccountIds.push(account.id);
      });
    } else {
      queriedAccountIds.push(query.accountId);
    }
    return queriedAccountIds;
  };

  var _getSubsetOfStatements = function(collection, query) {
    var queriedAccountIds = _getQueriedAccountIds(query);
    return _.chain(collection).filter(function(statement) {
      // Check against queried accounts and see if there are statements related to
      var result = _.contains(queriedAccountIds, statement.relatedToId());

      // Now AND the result with any specific document type the user is looking for
      result = result && (!query.type || statement.type().name === query.type.name);

      // Use combined result.
      return result;
    }).value();
  };


  $scope.$watchGroup(['account', 'statementType()', 'period()'], function(group) {
    var query = {};

    if (group[0].id !== 0) {
      query.accountId = group[0].id;
    }

    if ($scope.statementType().name !== null) {
      query.type = $scope.statementType();
    }

    var result = _getSubsetOfStatements(statements, query);
    $scope.statements = _.chain(result)
      .filter(function(statement) {
        return group[2].start === null || moment(statement.createdAt()).isAfter(group[2].start);
      })
      .sortBy(function(statement) {
        return statement.createdAt();
      })
      .value()
      .reverse();
    $scope.statementsByDate = groupByDateService.call($scope.statements, 'createdAt');
  });

  $scope.isDocumentVisible = function(document) {
    if($scope.account.isCombinedAccount()) {
      if (document.symbol) {
        const accounts = $scope.account.visibleAccounts()
        return _extractFundFactSymbols(accounts).includes(document.symbol)
      }
      return true
    } else if (document.symbol) {
      const modelPortfolio = $scope.account.accountGoals()[0].modelPortfolio();
      const allocations = (modelPortfolio === undefined) ? [] : $scope.account.accountGoals()[0].modelPortfolio().allocations()
      const fundFactDocSymbols = allocations.map(function(allocation) { return allocation.fund().symbol() })
      return fundFactDocSymbols.includes(document.symbol)
    } else {
      return ($scope.account.isCombinedAccount() ||
        !(document.account) || ($scope.account.id === document.account.id)
      );
    }
  };

  $scope.accountById = function(id) {
    if ($scope.currentUser) {
      return _($scope.currentUser.viewableAccounts()).findWhere({
        id: id
      });
    }
  };

  let _extractFundFactSymbols = function(accounts) {
    const allAllocations = accounts.map(function(account) {
      return account.accountGoals()[0].modelPortfolio().allocations()
    }).flat()

    const allFundFactSymbols = allAllocations.map(function(allocation) {
      return allocation.fund().symbol()
    })
    return [...new Set(allFundFactSymbols)]
  }
}
